/*************************************** General ***************************************/

.font-size-md {
  font-size: 1.2em;
}

.aboutSection,
.meettheinstructors,
.whatpeoplearesaying {
  margin: 2em 10%;
}

/*************************************** Header ***************************************/

.singlestudio-hero {
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}

.singlestudio-logo {
  margin-left: 1em;
}

.singlestudio-main_logo {
  max-height: 100%;
  max-width: 100%;
}

.freeTrial {
  margin-top: 20px;
  font-family: "CocoGothic", sans-serif;

  font-size: 12px;
  display: inline-block;
  padding: 9px;
  border-radius: 5px;
  letter-spacing: 1px;
  background-color: #9ac8b9;
}

.freeTrial a {
  color: white;
}

.freeTrial a:hover {
  color: white;
  text-decoration: none;
}

.freeTrial:hover {
  opacity: 0.9;
  cursor: pointer;
}

.hero-wrapper {
  margin: 3em;
  padding: 0.5em;
  text-align: center;
  background-color: hsla(0, 0%, 100%, 0.8);
  border-radius: 5px;
}

.main_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 100px;
  margin-top: 0px;
  margin: 0 auto;
}

.studio-headers {
  text-align: center;
  margin-top: 50px;
}

.studio-headers h1 {
  color: #a3d2c3;
}

/*************************************** Testimonials ***************************************/

.smaller-icon {
  background-repeat: no-repeat;
  background-position: 20px 35px;
  background-color: #f2f2f2;
  display: table-cell;
  padding: 30px 45px;
  text-align: center;
}

.whatpeopearesayinggrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-column-gap: 2.5vh;
  margin-top: 40px;
}

/*************************************** Info ***************************************/

.intro-container {
  background: linear-gradient(to right bottom, white 40%, 20%, #f5f5f5 60%);
}

.intro {
  display: grid;
  width: 100%;
  grid-template-rows: repeat(2, 1fr);
  justify-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.classbullet {
  height: 20px;
}

.equipmenticons {
  height: 8vh;
}

.singlestudio-stud {
  min-height: 0;
  margin: auto 0;
  /* **********Temporary image fix********** */
  /* max-height: 390px; */
  max-width: 500px;
}

.singlestudio-introcolumn {
  margin: 4em 0 4em 0;
}

/* .singlestudio-introcolumn h2 {
  font-size: 32px;
} */

.singlestudio-classgrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 4vh;
}

.singlestudio-slogan-message {
  font-size: 22px;
  font-family: "CocoGothic-Bold", sans-serif;
  letter-spacing: 1px;
  margin-top: 1rem;
  /* margin-left: 35px; */
  /* margin-top: 10vh; */
}

.levelsicon {
  height: 20vh;
}

.aboutSection {
  text-align: left;
  font-size: 16px;
  /* margin-left: 10%;
  margin-right: 0%;
  margin-top: 2em;
  margin-bottom: 2em; */
}

.section-title {
  position: relative;
  margin-top: 0;
  margin-left: 0;
  font-size: 2rem
}

.section-title::after {
  content: "";
  position: absolute;
  height: 2px;
  background: #9ac8b9;
  top: 100%;
  width: 7%;
  left: 0;
  margin: 5px 0px;
}

.aboutgrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-column-gap: 50px;
  margin-top: 40px;
}

.aboutSubtext2 {
  width: 80%;
}

.singlestudio-about_image {
  width: 100%;
}

/*************************************** Instructors ***************************************/

.meettheinstructors {
  text-align: left;
  font-size: 16px;
  /* margin-left: 10%;
  margin-right: 0%;
  margin-top: 2em;
  margin-bottom: 2em; */
}

.mappedInstructors {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* flex-direction: column; */
  align-items: center;
}

.singlestudio-instructor-bg {
  height: 70vh;
  width: 50vh;
}

.image-hover {
  filter: grayscale(27%) blur(2px) brightness(80%) opacity(30%);
  -webkit-filter: grayscale(27%) blur(2px) brightness(80%) opacity(30%);
  transform: scale(1.012);
  transition: 0.4s ease-in-out;
  z-index: 1;
}

.singlestudio-instructor-name {
  margin-top: 5vh;
  float: left;
  height: 70vh;
  width: 50vh;
  position: relative;
  text-align: center;
  font-size: 6vh;
  color: white;
}

.singlestudio-instructor-name .instructor-text {
  opacity: 0;
  font-size: 2.5vh;
  font-weight: bold;
  transition: 0.4s;
  position: absolute;
  color: #ffffff;
  text-align: center;
}
.singlestudio-instructor-name .instructor-text p:nth-child(2) {
  text-align: left;
}

.singlestudio-instructor-name:hover .instructor-text {
  opacity: 1;
  filter: none;
  -webkit-filter: none;
  transform: none;
  z-index: 1;
  padding: .5rem;
}

.instructor-text {
  padding: 0.25em;
  width: 100%;
  font-size: 1rem;
  letter-spacing: .5px;
  height: 100%;
  overflow: auto;
}

.text-element {
  text-align: center;
  color: #333333;
}


.text-element.bio {
  height: calc(100% - 40px);
  overflow-y: scroll;
  padding-bottom: 40px;
}

/*************************************** 'Access the web platform from anywhere' ***************************************/

.ending {
  text-align: left;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2em;
  margin-bottom: 2em;
}

.ending-subtext {
  width: 100%;
  margin: 2em;
}

.vertical-center {
  margin: auto 0;
}

/*************************************** Media queries ***************************************/

@media only screen and (min-width: 768px) {
  .intro {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: unset;
  }

  .singlestudio-classgrid {
    margin-top: 4vh;
  }

  .main_logo {
    margin: unset;
    justify-content: unset;
  }

  .mappedInstructors {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .hero-wrapper {
    text-align: left;
    padding: 2em;
  }

  .singlestudio-classgrid {
    grid-template-columns: 1fr 1fr;
  }

  .singlestudio-hero {
    justify-content: flex-start;
  }

  .singlestudio-instructor-bg,
  .singlestudio-instructor-name {
    height: 60vh;
    width: 35vh;
  }

  @media (max-width:900px) and (orientation: landscape) {
    .singlestudio-instructor-bg,
    .singlestudio-instructor-name {
      height: 60vw;
      width: 35vw;
    }
  }

  .singlestudio-instructor-name .instructor-text {
    font-size: 0.9rem;
  }

  .whatpeopearesayinggrid {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
}

.restrict-view {
  align-items: center;
}

@media only screen and (max-width: 480px) {
  .singlestudio-hero {
    background-size: 100% auto;
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    margin: 0;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hero-wrapper {
    margin: 2em;
    font-size: 18px;
    margin-right: 60px;
  }
  .main_logo {
    width: 170px;
    height: 90px;
  }
  .parent {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin: auto;
    height: auto;
    width: 100%;
    background-color: transparent;
    position: relative;
    padding-top: 100px;
  }
  .studio-videos-container {
    width: 101vw !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
  }

  .studio-headers h1 {
    font-size: 19px;
  }

  .singlestudio-stud {
    width: 92%;
    max-width: 400px;
    height: auto;
    margin-right: 50px;
  }
  .intro-container {
    width: 100%;
    text-align: center;
  }
  .intro {
    width: 92%;
    padding: 4%;
  }
  .singlestudio-introcolumn {
    width: auto;
  }
  .singlestudio-classgrid {
    width: 90%;
    text-align: left;
  }
  .equipmenticons {
    width: 90%;
    height: auto;
  }
  .aboutSection,
  .meettheinstructors,
  .whatpeoplearesaying {
    margin: 0;
  }
  .aboutgrid {
    width: 95%;
    position: inherit;
  }
  .section-title {
    width: 90%;
    text-align: center;
  }
  .aboutgrid div {
    width: 88%;
    text-align: center;
  }
  .whatpeopearesayinggrid,
  .singlestudio-instructor-name div {
    width: 95%;
    height: auto;
  }

  .instructor-text {
    max-height: 70vh;
  }
  .how-it-works {
    margin-right: 30px;
  }
  .ending-subtext {
    width: 88%;
  }
}

.replace-h2 {
  font-size: 32px;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
}

.replace-closingh1{
  text-align: center;
  color: rgb(163, 210, 195);
  font-size: 2.5rem
}

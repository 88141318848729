.carousel-wrapper {
  position: relative;
  width: 800px;
  height: 800px;

}


.iphone-image {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; 
  width: 400px;
}

.circle-container {
  position: relative;
  top: 10%; 
  right: 20%; 
  transform: translate(-50%, -80%);
  z-index: 1;
  width: 800px; height: 800px;
  transition: transform 0.7s ease;
}

.circle-item {
  position: absolute;
  top: 50%; left: 50%;
  transform-origin: 0 0;
  transition: transform 0.7s ease;
  scale: 0.5;
}


.brand-images-container {
  position: absolute;
  top: 35%; left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 600px; 
  height: 500px;
  overflow: hidden; 
}

.brand-image {
  position: absolute;
  top: 50%; left: 50%;
  width: 450px; 
  transform: translate(-50%, -50%);
  transition: transform 0.7s ease, opacity 0.7s ease;
}

.circle {
  position: relative;
  width: 100%;
  height: 100%;
  transform-origin: 50% 50%;
  transition: transform 0.7s ease;
}

.sprite-item {
  background-repeat: no-repeat;
  background-size: 3600px 200px; 
}

.centered-brand-container {
  position: absolute; 
  top: 50%; 
  left: 50%;
  width: 600px;      
  height: 600px;     
  transform: translate(-50%, -60%);
  z-index: 3; 
}

.centered-brand-container div {
  z-index: 3;
}

.brand-image {
  position: absolute;
  top: 43%;
  left: 66%;
  width: 500px; 
  height: auto;
  transform: translate(-50%, -50%);
  transition: transform 0.7s ease, opacity 1.2s ease;
  z-index: 3;
  scale: 1;
}

.brand-image.exiting {
  z-index: 3;
}
.brand-image.exiting.forward {
  animation: swipe-left 0.5s forwards;
  z-index: 3;
}
.brand-image.exiting.backward {
  animation: swipe-right 0.5s forwards;
  z-index: 3;

}


.brand-image.entering {
  z-index: 1;
  opacity: 0;
}
.brand-image.entering.forward {
  transform: translate(10%, -50%);
  animation: slide-in-center 0.7s forwards;
}
.brand-image.entering.backward {
  transform: translate(-10%, -50%); 
  animation: slide-in-center 0.7s forwards;
}
@keyframes swipe-left {
  to {
    transform: translate(-30%, -50%);
    opacity: 0;
  }
}
@keyframes swipe-right {
  to {
    transform: translate(30%, -50%);
    opacity: 0;
  }
}
@keyframes slide-in-center {
  0% {
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}
.Modal {
	width: 35%;
	background-color: white;
	position: absolute;
	top: 37.5%;
	left: 30%;
	direction: flex;
}

.prompt{
	text-decoration: underline;
	margin: 10px;
}

.parent {
	display: grid;
	grid-template-rows: 3;
	grid-template-columns: 3;
}

.title {
	grid-column-start: 1;
	grid-column-end: span col2-end;
}

.field {
	grid-column-start: 1;
	grid-row-start: 2;
}

.IO {
	font-size: 20px;
	font-family: futura-book, sans-serif;
}

.verify {
	grid-column-start: 2;
	grid-row-start: 2;
}

.cancel {
	grid-column-start: 2;
	grid-row-start: 3;
}

.response {
	color: red;
	grid-row-start: 3;
	grid-column-start: 1;
}


@media only screen and (max-width: 500px){
	.Modal {
	width: 94%;
	background-color: white;
	position: absolute;
	top: 37.5%;
	left: 3%;
	direction: flex;
}
}


@media only screen and (min-width: 500px)
and (max-width: 800px)
{
	.Modal {
	width: 55%;
	background-color: white;
	position: absolute;
	top: 37.5%;
	left: 22%;
	direction: flex;
}
}

@media only screen and (min-width: 800px)
and (max-width: 900px)
{
	.Modal {
	width: 45%;
	background-color: white;
	position: absolute;
	top: 37.5%;
	left: 27%;
	direction: flex;
}
}


@media only screen and(min-device-width: 375px)

and (max-device-height : 896px) 
    and (-webkit-device-pixel-ratio : 3){
		.Modal {
			width: 90%;
			background-color: white;
			position: absolute;
			top: 37.5%;
			left: 5%;
			direction: flex;
			}
    }
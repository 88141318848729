.js-background-EE8373 {
  background-color: #EE8373;
}

.js-background-white {
  background-color: #FFFFFF;
}

.js-background-black {
  background-color: #000000;
}

.js-background-9AC8B9-50 {
  background-color: rgba(154,200,185, .5);
}

.js-background-gray {
  background-color: rgba(0,0,0, .08);
}

.js-background-transparent {
  background-color: transparent;
}

.js-background-ECECEC {
  background-color: #ECECEC;
}

.js-background-9AC8B9 {
  background-color: #9AC8B9;
}

.js-background-1F3830 {
  background-color: #1F3830;
}

.js-background-F1F1F1 {
  background-color: #F1F1F1;
}

.js-background-FFFFFF {
  background-color: #FFFFFF;
}

.js-background-EEEEEE {
  background-color: #EEEEEE;
}

.js-custom-scrollbar-vertical::-webkit-scrollbar {
  display: initial !important;
}
.js-custom-scrollbar-horizontal::-webkit-scrollbar {
  display: initial !important;
}

.js-custom-scrollbar-horizontal{
  overflow-x: scroll;
}

.js-custom-scrollbar-vertical{
  overflow-y: scroll;
}

.js-select {
  border-width: "1px" !important;
  border-style: "solid" !important;
  border-color: "-internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133))" !important;
  color: "grey" !important;
}
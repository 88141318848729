.comment-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 270px;
}

.comment-stars {
    width: 100%;
    gap: 5px;
    display: flex;
}

.comment-section {
    min-height: 55%;
}

.comment-section p {
    color: #5c5959;
    font-size: 20px;
    font-family: "Raleway", sans-serif;
}

.comment-user-section {
    height: 100px;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
}

.comment-user-section img{ 
    width: 50px;
}

.comment-user-section h2 {
    color: #282828;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 36px;
    margin-top: auto;  
    margin: auto 0; 
}

/* Tablet Version */
@media (max-width: 1100px) {
    .comment-section p {
        font-size: 1.6vw;
    }

    .comment-user-section h2 {
        font-size: 2.6vw;
    }
}
/* End of Tablet Version */

/* Mobile Version */

@media (max-width: 625px) {
    .comment-container {
        width: 400px;
        height: 200px;
        
    }
    .comment-section p {
        font-size: 14px;
    }
    .comment-user-section h2 {
        font-size: 20px;
    }
}

/* End of Mobile Version */


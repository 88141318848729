.dashboard-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  gap: 40px;
}

.intro-rituwell-container {
  display: flex;
  width: 740px;
  padding: 30px 30px 15px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 26px;
  flex-shrink: 0;
  background-image: none;
}

#admin-white-background {
  position: relative;
  z-index: 1;
}


#admin-white-background::before {
  content: "";
  position: absolute;
  inset: 5% 2.5% 2.5% 2.5%;
  border-radius: 20px;
  background-color: white;
  z-index: -1;
}


#gradient-elipse {
  background: radial-gradient(
    ellipse at center,
    #ee837313 20%, 
    #ffffff09 80%  
  );
  width: 100%;       
  height: 100%;        
  position: absolute;
  top: 0%;
}


.intro-text-container {
  width: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
}

.intro-text-container h3 {
  color: #689385;
  text-align: center;
  font-family: 'Raleway';
  font-size: 24px;
  font-style: normal;
  font-weight: 750;
  line-height: normal;
  letter-spacing: -0.48px;
}

.intro-text-container h1 {
  color: #282828;
  text-align: center;
  font-family: 'Raleway';
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.intro-text-container p {
  color: #5c5959;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  letter-spacing: -0.4px;
}

.button-container {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 26px;
}

.dark-button {
  border: none;
  border-radius: 40px;
  border: solid 2px #689385;
  padding: 20px 25px;
  background: #689385;
  color: #fff;
  text-align: center;
  font-family: "Coco Gothic";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;

  &:hover {
    background-color: white;
    border: 2px solid #689385;
    color: #689385;
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
  }
}



.light-button {
  border: 2px solid #689385;
  border-radius: 40px;
  padding: 20px 25px;
  background: transparent;
  color: #689385;
  text-align: center;
  font-family: "Coco Gothic";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.48px;

  &:hover {
    background-color: #689385;
    color: white;
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
  }
}

.dashboard-img-container {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 2;
}

.dashboard-1-img {
  position: relative;
  z-index: 3;
}

.dashboard-2-img {
  position: absolute;
  top: 54px;
  left: -77px;
  z-index: 3;
}

.dashboard-3-img {
  position: absolute;
  top: -55px;
  right: 80px;
  z-index: 3;
}

.dashboard-4-img {
  position: absolute;
  bottom: 0px;
  right: -90px;
  z-index: 3;
}

.transition-container-rituwell {
  width: 100%;
  height: 100%;
  padding: 120px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}


.transition-chart-container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 96px;
}

.transition-text-container{
  width: 836px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 32px;
  z-index: 4;
}

.transition-text-container h2 {
  color: #253730;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 67px; /* 145.652% */
}

.transition-text-container .subtitle {
  color: #689385;

}

.how-it-works-container h1 {
  color: #282828;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 67px; /* 104.688% */

}

.how-it-works-container {
  display: flex;
  width: 100%;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 100px;
}

.cards-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 22px;
}

.card-container {
  transition: transform 0.3s ease;
  z-index: 1;
}

.card-container:hover {
  transform: scale(1.2);
  z-index: 10;
}

.options-container {
  width: 100%;
  height: 72vh;
  position: relative; 
  padding: 40px;
  background: linear-gradient(
    0deg,
    rgba(223, 239, 234, 1) 15%,
    rgba(189, 219, 209, 1) 60%
  );
  overflow: hidden;
}

.options-container > img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
}

.options-container h1 {
  color: #282828;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 67px; 
  margin-bottom: 20px;
}

.options-container p {
  color: #5c5959;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.4px;
  margin-bottom: 40px;
}


.options-text-container {
  position: relative;
  z-index: 1; 
  max-width: 800px;
  margin: 0 auto; 
}

.options-iphone-slider {
  position: relative;  
  top: 60%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -40%);
  width: 450px;
  height: auto;
  z-index: 3;
}

.options-studios-slider {
  width: 100%;
  height: 300px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 300px;
}

.options-studios-slider div {
  width: 200px;
  display: flex;
  align-items: center;
  margin: auto;
}

.options-studios-slider div > img {
    object-fit: contain;
}


.studio-mamasoul {
  width: 400px !important;
  margin-right: 80px !important;
  height: 100px;
}

.impact-container {
  width: 100%;
  padding: 120px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.impact-container h1 {
  color: #282828;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 46px;
  font-style: normal;
  font-weight: 900;
  line-height: 67px; /* 145.652% */
  /* I added the text shadow to make the text appear bolder, since font-weight maxes out at 900
  and it didn't look exactly like the figma file */
  text-shadow: 0.5px 0px 0px #282828, 
               -0.5px 0px 0px #282828; 

}

.impact-chart-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 24px 81px;
  gap: 48px;
}

.impact-chart-container h3 {
  width: 386px;
  color: #253730;
  text-align: center;
  font-family: 'Raleway' , sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 900;
  line-height: 43px; /* 153.571% */
  text-shadow: 0.5px 0px 0px #28282818, 
               -0.5px 0px 0px #28282813; 
  
}

.impact-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.voice-container {
  width: 100%;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
}

.voice-container h1 {
  color: #282828;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 46px;
  font-style: normal;
  font-weight: 00;
  line-height: 67px; /* 145.652% */
}

.comments-container {
  display: grid;
  height: 380px;
  padding: 20px 40px; 
  grid-template-columns: repeat(3, 1fr);
  gap: 2vw;
}




.ending-container {
  width: 100%;
  padding: 153px 94px 153px 114px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 66px;
  flex-shrink: 0;
  align-self: stretch;
}

.ending-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  
}

.ending-container h1 {
  color: #282828;
  font-family: 'Raleway', sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px; /* 147.619% */
  letter-spacing: -0.42px;
}

.ending-container h2 {
  color: #ee8373;
  font-family: 'Raleway', sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.34px;
}

.dark-red-button {
  border: none;
  border-radius: 27.5px;
  padding: 14px 20px;
  background: #ee8373;
  color: #fff;
  text-align: center;
  font-family: "Coco Gothic";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
  border: 2px solid #ee8373;

  &:hover {
    background-color: white;
    color: #ee8373;
    border: 2px solid #ee8373;
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
  }
}

.donut-chart-container {
  position: relative;

}

.donut-chart-container p {
  width: 100%;
  position: absolute;
  margin: 0;
  top: 35%;
  z-index: 1;
  color: #282828;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 570;
  line-height: normal;
  letter-spacing: -0.72px;
}
.mobile-break {
  display: none;
}

.donut-chart-container PieChart {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.highlight {
  color: #689385;
  font-family: 'Raleway', sans-serif;
}


/* Tablet Version */
@media (max-width: 1100px) {
  .comments-container {
    padding: 20px 80px;
  }
}
/* End of Tablet Version */

/* Mobile Version */
@media (max-width: 625px) {
  .dashboard-container {
    width: 100%;
    padding: 40px 0;
    margin-bottom: 100px;
  }
  .intro-rituwell-container {
    width: 100%;
    padding: 20px 20px;
  }
  .intro-text-container {
    width: 100%;

    > h3 { 
      font-size: 16px; 
    }
    > h1 { 
      font-size: 36px;
    }
    > p {
      font-size: 12px;
      padding: 0 20px;
    }
  }

  .button-container {
    width: 100%;
    gap: 10px;
    padding: 0 20px;
  }

  .dark-button {
    font-size: 16px;
    padding: 10px 0px;
    width: 50%;
  }
  .light-button{
    font-size: 16px;
    padding: 10px 0px;
    width: 50%;
  }

  .dashboard-img-container {
   
  }

  .dashboard-1-img {
    
    > img{
      width: 180%;
    }
  }
  .dashboard-2-img {
    width: 220px;
    left: 260px;
    top: -40px;
    >img {
      width: 80%;
    }
  }
  .dashboard-3-img {
    top: 220px;
    right: 200px;
    width: 200px;
    >img {
      width: 70%;
      object-fit: cover;
    } 
  }
  .dashboard-4-img {
    display: none;
  }

  .transition-container-rituwell {
    background: linear-gradient(0deg, rgba(223,239,234,1) 15%, rgba(189,219,209,1) 60%);

    padding: 80px 20px;
  }
  .transition-chart-container {
    gap: 0px;
    
  }
  .transition-text-container {
    width: 100%;
    justify-content: space-between;
    height: 100%;
    gap: 100px;
    >h2 {
      width: 100%;
      font-size: 28px;
      line-height: 47px;
    }
  }

  .subtitle {
    text-align: center !important;
    font-size: 48px !important;
    line-height: 56px !important;
    color: black !important;
    >span {
      color: #689385 !important;
    }
  
  }
  .mobile-break {
    display: inline;
  }
  .donut-chart-container {
    > p {
      top: 30%;
      font-size: 46px;
    }
  }
  .how-it-works-container {
    padding: 40px 0;
    padding-bottom: 100px;
    height: 100%;
    gap: 40px;
    >h1 {
      font-size: 36px;
    }
  }
  .cards-container {
    overflow-x: scroll;
    padding: 20px 20px;
  }

  .card-container:hover {
    transform: none;
    z-index: 10;
  }
  
  .card-container {
    margin-right: 30px;
  }

  .options-container {
    height: 760px;
    padding: 60px 20px;

  }
  .options-text-container {
    padding: 0 20px;

    >h1 {
      width: 100%;
      font-size: 28px;
      line-height: 40px;
    }
    >p {
      width: 100%;
      font-size: 14px;
    }
  }

  .options-studios-slider {
    display: none;
  }

  .impact-container {
    height: 100%;
    padding: 80px 20px;
  }
  .impact-chart-container {
    overflow-x: scroll;
    margin-bottom: 20px;
    width: 100%;
    padding: 0;
    gap: 20px;
  }
  .impact-text-container {
    padding: 0;
    margin: 0;
    /* display: flex;
    flex-direction: row; */

    /* >h3 {
      font-size: 18px;
      text-align: left;
      width: 200px;
    } */
  }

  .voice-container {
    gap: 20px;
    padding: 20px 20px;
    >h1 {
      font-size: 36px;
      line-height: 40px; /* 145.652% */
      
    }
  }

  .comments-container {
    overflow-x: scroll;
    width: 100%;
    padding: 20px 30px;
    height: 100%;
    gap: 20px;
  }

  .ending-container {
    padding: 60px 20px 0 20px;
    flex-direction: column-reverse;
    justify-content: space-between;

    
    > img {

    }
  }

  .ending-text-container {
    > div h1 {
      font-size: 26px;
      line-height: 50px;
      width: 100%;
      text-align: center;
    }

    >div h2 {
      text-align: center;
      font-size: 32px;
    }

    >button {
      margin: 0 auto;
      margin-top: -30px;
    }
  }
}
/* End of Mobile Version */
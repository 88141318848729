
#flyoutMenu {
  text-decoration-style: none;
  text-transform: uppercase;
  width: 100vw;
  height: 100vh;
  background-color: white;
  /* background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.8), rgba(128, 0, 128, 0.8)),url('../images/navbar_background.jpg'); */
  background-size: cover;
  background-position:center;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform .3s
              cubic-bezier(0, .52, 0, 1);
  overflow: scroll;
  z-index: 1000;
  color: #2C2C2C;
}

#flyoutMenu.hide {
  transform: translate3d(-100vw, 0, 0);
}

#flyoutMenu.show {
  transform: translate3d(0vw, 0, 0);
}

#flyoutMenu a{
  text-decoration: none;
  color: #2C2C2C;

}
.menu-options{
  padding: 10px;
  font-size: 3vw;
  cursor: pointer;

}

.menu-options li{
  display: block;
  padding: 20px;
  font-size: 16px;
  animation: showLinks .3s ease-in;
  transition: .1s ease-in;
}



.menu-studio-options a {
  text-transform: uppercase;
  text-decoration: none;
  padding: 5px;
  margin-left: 40px;
  display: inline-block;
  color: #2C2C2C;
  animation: showLinks .3s ease-in;
  transition: .1s ease-in;
}
.menu-studio-options > ul > ul > a {
  display: block;
  margin-left:40px;
}
@keyframes showLinks{
  0%{
    opacity: 0
  }

  100%{
    opacity: 1
  }
}

.flyoutMenu-header{
  position: relative;
  padding-bottom: 44px;
}

.flyoutMenu-logo{
  position: absolute;
  top:0;
  left: 0;
  width: 240px;
}

.flyoutMenu-close{
  position: absolute;
  top: 4px;
  right: -292px;
}

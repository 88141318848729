.unknown {
  background-color: #EFEDED;
  width: 100vw;
  height: 100vh;
}

#oops {
  width: 50%;
  height: 50%;
  margin-top: 150px;
  margin-left:auto;
  margin-right:auto;
}

#goback {
  width: 70%;
  height: 56px;
}

#text {
  width: 100%;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
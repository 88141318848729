.calendar-grid-container {
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  grid-gap: 40px;
  font-family: coco-gothic-regular;
}
.fa-sync {
  /* height: 10px;
  width: 10px; */
  margin: 0;
  border: none !important;
  padding: 0;
  animation: spin 3000ms infinite linear;
  /* cursor: none; */
  /* max-height: 38px; */
}
.fa-sync:hover {
  padding: 0 !important;
  margin: 0 !important;
  cursor: not-allowed;
}
.calendar-container > * .react-calendar__navigation button[disabled] {
  color: #000;
  background-color: white;
  color: black;
  font-size: 20px;
  color: black;
}
.Dropdown-menu {
  width: max-content !important;
}
.Dropdown-option {
  padding-right: 20px !important;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
    transform-origin: center center;
  }
  to {
    transform: rotate(360deg);
  }
}

.calendar-grid-item {
  height: fit-content;
}
.schedContainer {
  width: 20%;
}
.sync {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
}
.syncRoot {
  /* font-weight: 900; */
}
.syncControl:hover {
  cursor: pointer;
  box-shadow: 1px 1px 2px 2px rgb(223, 223, 223) !important;
}
.syncPlaceholder {
  white-space: nowrap;
  color: rgb(127, 128, 126);
}
.syncControl {
  border: none !important;
  padding-right: 20px !important;
}
.arrowClassName {
  top: 0 !important;
  right: 0 !important;
}
.sync button {
  border-radius: 20px;
  font-size: 13px;
  margin: 6px;
  white-space: nowrap;
  padding: 2px;
  /* height: 38px; */
}
.sync button:disabled {
  cursor: not-allowed;
}

.container-content {
}



.calendar-grid-container > .sidebar-item-container:last-child {
  background-color: transparent;
}

.calendar-container {
  display: flex;
  justify-content: space-around;
  height: auto;
}

.calendar-container > .react-calendar {
  width: 70%;
  line-height: 2em;
  height: 100%;
  border: 0px;
  color: rgb(128, 128, 126);
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  margin: 2em 0;
}

.react-calendar button {
  position: relative;
}

.react-calendar > * button > .circle {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin: -38px 0px 0px 0px;
  margin-left: auto;
  margin-right: auto;
}

.react-calendar > * .scheduled > .circle {
  border: 3px solid #9ac8b9;
  z-index: 3;
}

.react-calendar__tile--now:enabled > .circle {
  /* background-color: #a8d4ff !important; */
  border: 2px solid rgb(125, 146, 165);
  border-radius: 20px;
  color: white;
  z-index: 3;
}

.react-calendar > * .completed > .circle {
  background-color: #9ac8b9;
  z-index: 0;
}

.react-calendar > .skipped {
  background-color: #7d92a5;
  z-index: 0;
}

.react-calendar > * button > time {
  z-index: 1;
}

.completed > abbr,
/* .react-calendar > * .scheduled > abbr , */
.skipped > abbr {
  color: white;
  z-index: 40;
}
/* .react-calendar__tile--now > abbr {
  color: #257afd !important;
} */

.react-calendar__tile--now:enabled:focus * > abbr {
  color: #257afd !important;
}

.react-calendar > * .scheduled,
*.completed > time {
  color: rgb(128, 128, 126);
}

.react-calendar__month-view__days__day--weekend {
  color: rgb(128, 128, 126);
}

.react-calendar__tile--active > abbr {
  /* background-color: white; */
  color: #000000 !important;
  font-weight: bolder;
}
.react-calendar__tile--active.skipped abbr {
  /* background-color: white; */
  /* color: #257afd !important; */
}
.react-calendar__tile abbr {
  z-index: 4;
}

.react-calendar__tile--active:enabled:focus {
  background-color: white;
  color: #257afd !important;
}

#new-class-schedule {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(128, 128, 126);
}

#new-class-schedule > p {
  font-family: coco-gothic-regular;
}

#new-class-schedule > .schedule {
  width: 146px;
  background-color: rgb(154, 200, 185);
  color: white;
  border-radius: 12px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}
.Dropdown-arrow-wrapper {
  position: absolute;
  top: 16px;
  right: 5px;
}

#new-class-schedule > .schedule:hover {
  background-color: #728e85;
}

.calendar-grid-container > * .monthly-totals {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: rgb(128, 128, 126);
  width: 75%;
}

.calendar-grid-container > * .monthly-totals > div {
  display: flex;
  justify-content: space-around;
}

.calendar-grid-container > .sidebar-item-container:nth-child(2) {
  padding: 0px;
  width: 80%;
  margin: auto;
}

.calendar-container > .slick-initialized {
  width: 20%;
}

div.react-calendar__month-view__days > button.react-calendar__tile:hover,
div.react-calendar__month-view__days
  > button.react-calendar__month-view__days__day:hover {
  background-color: white;
}

div.react-calendar__month-view__weekdays
  > div.react-calendar__month-view__weekdays__weekday
  > abbr {
  cursor: default;
}

.react-calendar__tile {
  background: none !important;
}

@media only screen and (max-width: 800px) {
  .calendar-container {
    /* flex-direction: row;
    flex-wrap: wrap; */
    flex-direction: column;
    align-items: center;
  }
  #new-class-schedule {
    width: 100%;
  }
  .sync {
    justify-content: center;
    width: min-content;
    margin-left: auto;
    margin-right: auto;
  }
  .sync button {
    width: auto;
  }

  .schedContainer {
    width: 100%;
  }
  .calendar-container > .react-calendar {
    border: none;
    width: 90%;
    line-height: 1em;
  }
  .react-calendar > * button > .circle {
    margin: -25px 0px 0px -4px;
    width: 35px;
    height: 35px;
  }
  .calendar-grid-container > * .monthly-totals {
    flex-direction: column;
    align-items: flex-start;
  }
  .calendar-grid-container > * .monthly-totals > div {
    padding: 2%;
  }
  .calendar-container > .react-calendar {
    margin: 0%;
  }
  .schedule-wrapper > .day-wrapper {
    margin: 0ch;
    padding-left: 20px;
    flex-direction: column;
    border-left: 4px solid rgba(154, 200, 185, 0.33);
  }
  .day-wrapper > .workouts-wrapper {
    width: unset !important;
  }
  .schedule-master > .schedule-wrapper {
    width: unset !important;
    border: none !important;
    padding: 10px;
  }
  .workouts-wrapper > .workout {
    box-shadow: 0px 4px 12px -1px grey;
    display: flex;
  }
  .workouts-wrapper > .workout > .title-wrapper,
  .workouts-wrapper > .workout > div:first-child {
    font-size: 12px;
  }
  .calendar-grid-container > .sidebar-item-container:nth-child(2) {
    width: 90%;
  }
  .day-wrapper > div:first-child {
    margin-left: -22px;
  }
  .day-wrapper > * .workout-date {
    font-size: 18px;
    position: relative;
    top: -13px;
    right: 25px;
  }
  .day-wrapper > * .day-circle {
    position: relative;
    top: -10px;
    right: 19px;
  }
  .calendar-container > .slick-initialized {
    width: 100%;
  }
  .calendar-container .slick-list {
    top: unset !important;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
}

.workout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.workout-new-grid {
  display: grid;
  grid-template-columns: 3fr 1fr 9fr;
}

.title-wrapper {
  display: flex;
  align-items: center;
}

#workout-time {
  display: flex;
  align-items: center;
}

/* Tablets */

/* ipad */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .react-calendar > * button > .circle {
    margin: -24px 0px 0px 20px;
  }
}

/* ipad pro */

@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .react-calendar > * button > .circle {
    margin: -33px 0px 0px 20px;
  }
}
// Mailchimp CSS
@import url("//cdn-images.mailchimp.com/embedcode/classic-10_7.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans";
  position: relative;
}

.Background-color-nav {
  width: 100%;
  height: 72px; //Maybe bring back after promo banner is removed
  z-index: 0;
}

@media only screen and (max-width: 800px) {
  .Background-color-nav {
    height: 100px;
  }
  #hubspot-messages-iframe-container.widget-align-right {
    width: 100% !important;
  }
}

@font-face {
  font-family: "Coco Gothic";
  font-weight: 700;
  font-style: normal;
  src: url("/assets/fonts/CocoGothic-Bold.woff") format("woff");
}

@font-face {
  font-family: "Coco Gothic";
  font-weight: 700;
  font-style: italic;
  src: url("/assets/fonts/CocoGothic-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Coco Gothic";
  font-weight: 300;
  font-style: normal;
  src: url("/assets/fonts/CocoGothic-UltraLight.woff") format("woff");
}

@font-face {
  font-family: "Coco Gothic";
  font-weight: 300;
  font-style: italic;
  src: url("/assets/fonts/CocoGothic-UltraLightItalic.woff") format("woff");
}

@font-face {
  font-family: coco-gothic-regular;
  src: url("/assets/fonts/Coco-Gothic-Regular-OTF.otf");
}

@font-face {
  font-family: coco-gothic-ultralight;
  src: local("coco-gothic-ultralight"),
    url("./fonts/coco-gothic-ultralight.ttf");
}

@font-face {
  font-family: futura-book;
  src: local("futura-book"), url("./fonts/futura-book-font.ttf");
}

.Signup-button {
  background-color: #9ac8b9;
  color: white;
  padding: 20px 30px 20px 30px;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
  text-align: center;
  z-index: -1;
}

.Button-style {
  padding: 20px;
  border: none;
  background-color: transparent;
  text-decoration: none;
  color: black;
}

.Login-signup {
  padding: 20px;
  position: absolute;
  right: 0;
}

.Font {
  font-size: 20px;
  font-family: coco-gothic-ultralight, sans-serif;
}

.Font9 {
  font-size: 20px;
  font-family: coco-gothic-ultralight, sans-serif;
}

.Image {
  max-width: 100%;
  height: auto;
}

.Main-text {
  text-align: center;
  font-size: 35px;
  font-weight: 510;
}

.Center {
  text-align: center;
}

.Padding {
  padding: 10px;
}

.Input-style {
  color: "#000";
  max-width: 300px;
  color: black;
  background-color: #eeeeee;
  padding: 6px 20px;
  border: none;
  display: inline-block;
  font-family: futura-book, sans-serif;
}

.Input-login-style {
  color: "#000";
  width: 250px;
  color: black;
  background-color: #eeeeee;
  padding: 6px 20px;
  border: none;
  display: inline-block;
  font-family: futura-book, sans-serif;
}

.No-highlight-border:focus {
  outline-width: 0;
}

.Login-button {
  text-align: center;
  background-color: rgb(138, 187, 171);
  padding: 6px 20px;
  opacity: 0.5;
  font-family: coco-gothic-ultralight, sans-serif;
}

.modal {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  border: 1px solid;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  max-width: 500px;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(255, 255, 255, 0.801);
  opacity: 30%;
  margin: 0 auto;
}

.modal-content {
  background-color: #fefefe;
  margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

.Terms-font {
  font-size: 15px;
}

.Terms-link {
  color: #9ac8b9;
}

.Login-now {
  padding: 10px;
  border: none;
  background-color: transparent;
  color: #9ac8b9;
  text-decoration: none;
}

.Numbers {
  font-family: futura-book;
  font-size: 15px;
}

.PrivacyPolicy {
  font-family: futura-book;
  margin: 100px;
  top: 10%;
  position: absolute;
}

@media only screen and (max-width: 800px) {
  #hubspot-messages-iframe-container.widget-align-right {
    width: 100% !important;
  }
}

.checkout-text {
  text-align: center;
  font-size: 32px;
  font-weight: 800;
  color: #000000;
  padding: 20px;
}

.checkout-container {
  width: 60%;
  padding-top: 120px;
  margin: auto;
}

.checkout-form {
  z-index: 0;
  display: flex;
  flex-direction: row;
  gap: 5%;
}

.checkout-form div {
  flex: 1;
}

.checkout-products {
  display: flex;
  flex-direction: column;
}

.checkout-product-tile-wrapper {
  height: auto;
  padding: 0px;
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(53, 54, 54, 0.3);
  border-radius: 4px;
  margin-top: 20px;
  cursor: pointer;
  padding-left: 25px;
  padding-right: 25px;
  user-select: none;
}

.checkout-product-type-text {
  padding: 0;
  margin: 0;
  font-size: 16px;
  margin-top: 30px;
  font-weight: 800;
}

.checkout-product-tile-checkbox-container {
  flex-grow: 0 !important;
  border-right: 1px solid rgba(53, 54, 54, 0.3);
  padding-right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rounded-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid;
  appearance: none;
  outline: none;
  cursor: pointer;
}

.rounded-checkbox:checked {
  appearance: auto;
  clip-path: circle(50% at 50% 50%);
  background-color: rgb(0, 0, 0) !important;
}

.checkout-product-tile-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.checkout-product-tile-price {
  flex-grow: 0 !important;
  align-items: center;
  justify-content: center;
  display: flex;
}

.checkout-payment-form {
  
}

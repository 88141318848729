.progress-container {
  display: flex;
  flex-direction: column;
  height: 82%;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  // font-size: 28px;
  // animation: fadeIn 1s;

  > div {
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }

  > button {
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
}

.progress-bar {
  height: 27px;
  border-radius: 10px;
  border: 1px solid #333;
  background-color: #f4f6f5;
  box-shadow: 0px 4px 14px 1px rgba(153, 153, 153, 0.6);
}

.filler {
  background: #9ac8b9;
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
}

.alltimestats-container {
  display: flex;
  flex-direction: column;
  height: 32%;
  width: 100%;
  justify-content: space-evenly;
  // align-items: center;
  // text-align: center;
  margin-bottom: 1%;
  // font-size: 28px;
  // animation: fadeIn 1s;

  > div {
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }

  > button {
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
}

.titles {
  margin-top: 2%;
  margin-bottom: 2%;
}

.titles h1 {
  flex-direction: row;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 35px;
  text-align: center;
  margin-bottom: 24px;
  top: 20px;
}

hr {
  width: 96%;
}

.sweat-equity {
  display: inline;
  float: left;
  left: 40px;
}

.all-time-stats {
  display: inline;
  float: right;
  right: 40px;
}

// .stats {
// 	flex-direction: row;
// }

.total-stats {
  top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.total-stats > div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.total-stats > div > div {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  margin-left: 20px;
}

.total-stats > div > div > span {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  padding: 10px 0px 12px 0px;
}

// .total-stats > div > hr {
// 	border: 1px solid #9ac8b9;
// 	transform: rotate(90deg);
// 	width: 100px;
// 	height: 0px;
// }

.total-stats > div > div > vl {
  border: 1px solid #9ac8b9;
  height: 100px;
  margin: 50px;
}

div > vl {
  border: 1px solid #9ac8b9;
  height: 100px;
}

// .dumbbell {
// 	display: flex;
// 	flex-direction: row;
// }

// .fire {
// }

// .clock {
// }

.noprogress {
  text-align: center;
  font-size: 28px;
  margin: 10%;
  margin-top: 120px;
}
.chart-titles {
  display: inline;
  align-self: stretch;
}
.monthly-overview {
  float: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 35px;
  text-align: center;
  padding: 30px 0px 0px 40px;
}
.dropdown-container {
  display: flex;
  width: 300px;
  justify-content: space-evenly;
  float: right;
  padding: 30px 0px 0px 0px;
}

.progress-background {
  height: 100%;
  width: 100%;
  background: white;
  display: flexbox;
  margin-top: 1%;
}

.totals-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.bottom-container {
  align-items: center;
  display: block;
  width: 100%;
  margin-top: 120px;
}

.noDataText {
  display: flex;
  // flex-direction: column;
  width: 40%;
  justify-content: space-around;
  // align-items: center;
  font-size: 2vmax;
  margin-top: -200px;
  margin-left: 40px;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0.75;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0.75;
  }
}

@media only screen and (max-width: 768px) {
  .alltimestats-container {
    justify-content: start;
    height: 20%;
  }
  .monthly-overview {
   
    padding: 30px 0px 0px 20px;
  }

  .titles h1 {
    font-size: 14px;
    line-height: 16px;
  }

  .sweat-equity {
    left: 12px;
  }

  .all-time-stats {
    right: 12px;
  }

  // .stats {
  // 	flex-direction: row;
  // }

  .total-stats {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .total-stats svg {
    width: 60px;
    margin-left: 10px;
  }

  .total-stats .clock svg {
    width: 60px;
  }

  .total-stats > div {
    width: 33%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .total-stats > div > div {
    font-size: 10px;
    line-height: 12px;
    margin-left: 10px;
  }

  .total-stats > div > div > span {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    padding: 10px 0px 12px 0px;
  }

  .total-stats > div > div > vl {
    border: 1px solid #9ac8b9;
    height: 100px;
    margin: 0;
  }

  .monthly-overview {
    font-size: 1rem;
    line-height: 1rem;
  }

  .sidebar-item-container {
    width: 60%;
  }
  .noDataText {
    display: flex;
    // flex-direction: column;
    width: 70%;
    justify-content: space-around;
    // align-items: center;
    font-size: 3vmax;
    margin-top: 30px;
    margin-left: 40px;
  }
  .progress-background {
    width: 100%;
    margin: auto;
  }
  .progress-circles-body {
    width: 87vw;
  }
  .progress-circles {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 0 auto;
  }
  .circle-test {
    margin: 0 !important;
    width: 90% !important;
  }
  .red-circle-test {
    margin: 0 !important;
    width: 90% !important;
  }

  .progress-circles {
    justify-content: space-between;
    flex-direction: row;
  }
  .progress-container {
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
  }

  .dropdown-container {
    padding: 4%;
    // margin-top: 2%;
    width: 200px;
    height: 0px;
    padding-top: 25px;
  }
  .dropdown-container button {
    width: 50px;
    height: 30px;
    font-size: 10px;
  }

  .totals-container {
    padding: 1%;
  }
  .progress-chart-section {
    margin-bottom: 50px;
    display: flex;
    width: 300px; //idk why but it worked
    transform: translate(-15px);
  }
  .circle-text-container > span {
    border: none !important;
    // font-size: smaller;
    padding: 20px 10px 20px 10px !important;
  }
  .circle-sub-text {
    text-align: right;
    font-size: smaller;
  }
  .recharts-surface {
    width: 100%;
    // margin-left: 29.5%;
    margin-left: auto;
    //width: 95vw !important;
    height: 100% !important;
    padding-bottom: 20%;
  }
  .dropdown-menu {
    position: absolute;
    will-change: transform;
    top: 2.2rem !important;
    left: none !important;
    transform: none !important;
  }
}

@media only screen and (max-device-width: 900px) and (orientation: landscape) {
  .sidebar-item-container {
    width: 60%;
  }
  .noDataText {
    display: flex;
    // flex-direction: column;
    width: 70%;
    justify-content: space-around;
    // align-items: center;
    font-size: 3vmax;
    margin-top: 30px;
    margin-left: 40px;
  }
  .progress-background {
    width: 95%;
    margin: auto;
  }
  /* 
  .progress-circles-body {
    width: 30%;
    margin-right: 70px;
  }
  */
  .circle-test {
    margin: unset !important;
    width: 80px !important;
  }
  .red-circle-test {
    margin: unset !important;
    width: 80px !important;
  }
  .circle-text-container {
    width: 40%;
  }
  .progress-circles {
    justify-content: space-between;
    flex-direction: row;
  }
  .progress-container {
    justify-content: unset;
    height: 200vh;
  }
  .dropdown-container {
    padding: 4%;
  }
  .totals-container {
    padding: 0;
  }
  .progress-chart-section {
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
  }
  .circle-text-container > span {
    border: none !important;
    // font-size: smaller;
    padding: 20px 10px 20px 10px !important;
  }
  .circle-sub-text {
    text-align: right;
    font-size: smaller;
  }
  .recharts-surface {
    width: 68% !important;
    margin-left: 16%;

    padding-bottom: 0%;
  }
  .dropdown-menu {
    position: absolute;
    will-change: transform;
    top: 2.2rem !important;
    left: none !important;
    transform: none !important;
  }
}

.circle-sub-text {
  text-align: center;
  display: flex;
  justify-content: center;
}

@media only screen and (max-device-width: 767px) and (orientation: landscape) {
  .sidebar-item-master {
    margin-top: 60px;
    margin-bottom: 0;
  }
}

.sidebar-item-master {
  min-height: 80vh;
  margin-left: 250px;
  margin-bottom: 20px;
  display: grid;
  position: relative;
}

.sidebar-item-master.default {
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
}

.sidebar-item-container {
  /* background-color: #f2f6f5; */
  border-radius: 4px;
  height: 100%;
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
}

.dashboard-specific-stylings .sidebar-item-container {
  display: grid;
}

.sidebar-item-container > * p {
  font-family: coco-gothic-regular;
}

.buffer {
  position: absolute;
  width: 100%;
  top: 75px;
  /* margin-left: calc(4% + 205px); */
  background-color: #ffffffe6;
}

.pure-g {
  margin-top: 10px;
}

@media only screen and (max-width: 800px) {
  .sidebar-item-master {
    /*margin-left: 1vw;*/
    margin-left: 0vw;
    margin-bottom: 0;
    /* margin-top: 30px; */
    min-width: 320px;
    max-width: 800px;
    width: calc(100vw);
  }
}

.homepage-video-container {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
}

.js-video-background {
  /* position: absolute; */
  width: 100%;
  object-fit: cover;
  /* check this one with Alexandra */
  height: 100%;
  /* margin-top: 71px; */
}

.js-video-background > .video-react {
  padding-top: unset !important;
  height: 100% !important;
}

.js-video-background > .video-react > .video-react-video {
  height: 100% !important;
  object-fit: cover !important;
  width: 100% !important;; 
}


.overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  opacity: 0.2;
  z-index: 1;
}

div.Box > a.Video-text {
  opacity: 0.7;
}

/* Mobile view */

@media only screen and (max-width: 500px) {
  .home-video {
    width: 100%;
    margin: auto;
    /* padding-top: 74px */
  }
}

.Payment-input{
  color: '#000';
  max-width: 500px;
  color: black;
  background-color:#eeeeee;
  padding: 6px 10px;
  border: none;
  display: inline-block;
  font-family: futura-book, sans-serif;
}
.Label-left{
  position: relative ;
  right: 5% ;
}
.Label-center{
  position:static;
  left:0;
}
.Save-banner{
  position: relative;
  width: 38%;
  bottom: 13%;
  left: 30%;
}
.Payment-monthly{
  position: relative;
  top: 1%;

}
.Payment-yearly{
  position: relative;
  bottom: 1%;
}
.Payment-description{
  width:70%;
  padding-top:20px;
  padding-bottom:20px;
  margin:0 auto 0 auto;
  font-size:13px;
  font-family:futura-book;
}

.linkprivacy{
  text-decoration:underline;
  color:#9ac8b9;
}

.buttons {
  display: none;
}

@media only screen and (max-height: 1600px){
	.footer-payment {
		position: relative;
	}
}

@media screen and (max-width: 1024px) {
  .checkout-product-type-text {
    margin-top: 20px;
  }

  .checkout-text {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .checkout-payment-form .checkout-text {
    padding: 25px;
  }
}

@media only screen and (max-width: 767px) {

  @keyframes tonext {
    75% {
      left: 0;
    }
    95% {
      left: 100%;
    }
    98% {
      left: 100%;
    }
    99% {
      left: 0;
    }
  }
  
  @keyframes tostart {
    75% {
      left: 0;
    }
    95% {
      left: -300%;
    }
    98% {
      left: -300%;
    }
    99% {
      left: 0;
    }
  }
  
  @keyframes snap {
    96% {
      scroll-snap-align: center;
    }
    97% {
      scroll-snap-align: none;
    }
    99% {
      scroll-snap-align: none;
    }
    100% {
      scroll-snap-align: center;
    }
  }

  .checkout-container {
    display: flex;
    justify-content: center;
  }

  .checkout-form {
    flex-direction: column;
    width: 100%;
    gap: 0;
  }

  .product-list-container {
    width: 50vw;
    display: flex;
    flex-direction: column;
  }

  .checkout-products ul {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch !important;
    gap: 0.5rem;
  }
  
  .checkout-products ul > * {
    flex: 0 0 auto;
    flex-basis: 95%;
    scroll-snap-align: center;
    flex-direction: column;
    align-items: center;
    border: 0px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border-radius: 9px;
  }

  .checkout-product-tile-wrapper {
    padding: 25px;
    border: 0px;
    margin: 20px;
  }

  .checkout-product-tile-body {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem;
    gap: 15px;
  }

  .buttons > * {
    width: 50%;
  }
  
  .checkout-product-tile-checkbox-container {
    display: none;
  }
}
.js-main-player {
  object-fit: cover;
}
/* hide scroll bar but keep function */
::-webkit-scrollbar {
  display: none;
}

.video-streaming {
  border-radius: 7px;
  text-align: right;
  width: 100%;
}

.message-indication {
  font-size: 18px;
  font-family: Open Sans;
  color: white;
  width: 60%;
  text-align: center;
}

.video-debugger-button {
  position: absolute;
  left: 0;
  z-index: 999;
  border-radius: 100%;
  margin-left: 5px;
  opacity: 0.5;
  color: #000000;
  padding: 4px;
  cursor: pointer;
}

.video-debugger-button:hover {
  opacity: 0.8;
}

.video-debugger {
  position: absolute;
  top: 20px;
  background: #333;
  margin: 20px;
  padding: 10px;
  opacity: 0.9;
  color: white;
  width: 765px;
}

.video-debugger ul {
  list-style-type: none;
  height: 360px;
  overflow: scroll;
}

.video-debugger-close {
  cursor: pointer;
  float: right;
  z-index: 99;
}

.video-debugger-close:hover {
  opacity: 0.3;
}

.video-react .video-react-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.js-my-video {
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.live-video-detail-container {
  width: 100%;
  background-color: white;
  border-radius: 4px;
  margin: auto;
  padding: 1em;
}

/* @media only screen and (max-width: 1472px) {
  .live-video-detail-container {
    overflow-x: auto;
  }
} */

.live-video-controls hr {
  width: 100%;
}

.live-video-controls {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  width: 100%;
  height: 100%;
  transition: background-color 0.5 linear;
}

.live-video-controls.self > * {
  position: relative;
  /* z-index: -1; */
}

.live-video-controls:hover .live-video-control {
  display: block !important;
}

.live-video-controls.self {
  background-color: rgba(0, 0, 0, 0);
}

.live-video-controls.self:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.live-video-controls.self:hover > * {
  z-index: 1;
}

.js-hidden {
  display: none !important;
}

.live-video-control {
  display: none;
  border: none;
  background-color: transparent;
}

.live-video-control-join-class {
  border: none;
  background-color: transparent;
}

/* .live-video-control-video {
  margin-right: 20px;
} */

.controls-hidden {
  display: none !important;
}

.two-way-video-container {
  display: flex;
  flex-direction: row;
}

.video-react .video-react-control-bar {
  background-color: rgba(196, 196, 196, 0.5) !important;
  /* height: 30px; */
}

.video-react-play-progress.video-react-slider-bar {
  display: none !important;
}

.js-two-way-fullscreen-btn {
  background-color: transparent !important;
}


.js-two-way-fullscreen-btn:before {
  display: flex;
  align-items: center;
  justify-content: center;
}

.js-microphone-icon {
  width: 2.0vw;
}

.js-camera-icon {
  width: 2.0vw;
}

.js-live-feed-control {
  width: 800px !important;
  position: relative;
  border-radius: 7px;
  background-color: black;
}

.video-streaming-element {
  object-fit: cover;
}

.live-video-controls-header {
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: 10px;
}


@media (max-width: 767px) {
  .message-indication {
    font-size: 18px;
    font-family: Open Sans;
    color: white;
    width: 90%;
    text-align: center;
  }
  .streaming-features-container {
    padding-left: 20px;
  }

  .js-live-video-name {
    position: inherit !important;
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    justify-content: left;
    align-items: flex-start;
  }

  .live-videos-container .live-video-controls {
    display: flex !important;
    position: inherit !important;
    background: transparent !important;
    flex: 1;
  }

  .streaming-features-container {
    padding-left: 0px;
  }

  .live-videos-container .live-video-controls .live-video-control {
    display: block !important;
  }

  .video-streaming-container > .video-streaming {
    width: 150px !important;
  }

  .video-streaming-container {
    /* height: 120px !important; */
    width: 100% !important;
    position: relative;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    gap: 10px;
  }

  .live-video-controls-header {
    margin-top: 10px;
  }

  .live-instructor-controls-wrapper {
    width: 50%;
  }
}

@media (min-width: 768px) {
 
  /* change size of icon */
  .video-react *, .video-react *:before, .video-react *:after {
    font-size: 18px;;
  }

  .video-panel {
    padding-left: 10px;
    padding-right: 10px;

  }
  .js-loading-wrapper {
    margin-top: 5vh;
    text-align: center;
    width: 40%;
    height: 30px;
  }

  .video-streaming-element {
    width: 100%;
  }

  .live-video-controls-header {
    font-size: 20px;
    font-weight: bolder;
    margin-bottom: 10px;
  }
  .live-video-control-small > svg {
    width: 3vw;
  }

  .js-my-video-feed-control {
    height: 450px;
    object-fit: cover;
  }

  .video-react .video-react-control-bar {
    height: 46px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
  }

  .video-react .video-react-control-bar {
    width: 56.69791vw !important;
    margin: 0 auto;
  } 

  .jetsweat-progress-bar {
    width: 30vw !important;
    height: 11px !important;
    border-radius: 10px;
    min-width: unset !important;
    padding-left: 1vw !important;
    padding-right: 1vw !important;
  }

  .js-two-way-video-instructor-btn > img {
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    height: 1.8vw;
  }

  .js-two-way-video-instructor-btn {
    margin-right: 1vw;
  }
}



/* Desktops and laptops (thumbnail images for live classes) ----------- */
@media only screen and (min-width: 768px) {
  .video-streaming-element {
    width: 100%;
    height: 80px;
  }
}

@media only screen and (min-width: 1200px) {
  .video-streaming-element {
    width: 100%;
    height: 110px;
  }
}

@media only screen and (min-width: 1600px) {
  .video-streaming-element {
    width: 100%;
    height: 130px;
  }
}

@media only screen and (min-width: 2000px) {
  .video-streaming-element {
    width: 100%;
    height: 160px;
  }
}

/* Large screens (thumbnail images for live classes) ----------- */
@media only screen and (min-width: 2500px) {
  .video-streaming-element {
    width: 100%;
    height: 220px;
  }
}



.js-fullscreen-button {
  background-color: transparent;
}

.fullscreen-txt {
  font-size: 25px;
  font-weight: 700;
}

.addfriend-text {
  font-size: 0.9vw;
  font-weight: 700;
  margin-right: 2px !important; 

}

.video-panel {
  width: 40%;
  z-index: 50;
  padding-bottom: 10px;
  border-radius: 7px;
  margin-left: 7px;
  background-color: rgb(205,205,204);
  color: white;
}

.js-feedcontrol-overlay {
  background-color: transparent;
}

.js-feedcontrol-overlay-join-wrapper {
  padding: 10px;
  height: 100%;
}

.js-feedcontrol-overlay-join-button {
  border-radius: 4px;
  border: none;
}


@media (min-width: 768px) {

  .js-feedcontrol-overlay-join-button {
    margin-top: 5vh;
    min-width: 10.9092vw;
    padding-left: 1vw;
    padding-right: 1vw;
    width: 11vw;
    height: 4vw;
  }
  .live-videos-container {
    height: 29.66vw;
  }
  .fullscreen-dropdown-toggle {
    width: 110px;
  }
  .sort-container-fullscreen {
    border-bottom: 1px solid white;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
  }
  .live-videos-container-fullscreen {
    /* overflow: scroll; */
    height: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 767px) {

  .js-two-way-fullscreen-btn > img {
    width: 20px;
  }

  .js-feedcontrol-overlay-join-button {
    margin-top: 4vh;
    min-width: 10.9092vw;
    padding-left: 1vw;
    padding-right: 1vw;
    width: 22vw;
    height: 10vw;
  }
  .fullscreen-dropdown-toggle {
    width: 90px;
  }

  @media only screen and (max-width: 767px) {
    .sort-container-fullscreen {
      border-bottom: 1px solid white;
      margin-bottom: 0px;
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      color: #ffffff;
      font-size: 20px;
      font-weight: 700;
    }
  }
  .js-live-video-name {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px;
    /* margin-bottom: 20px; 
    Commented out during work on mobile responsiveness */
    color: white;
    font-weight: bold;
  }
  .live-videos-container-fullscreen {
    overflow: scroll;
    height: 100% !important;
    align-items: center;
  }
}


.js-live-video-name {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px;
  color: white;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
}

/* remove small white square in friend list. js-two-way-video-view-container already has scroll  */
/* .live-videos-container {
  overflow: scroll;
} */

.video-panel-fullscreen {
  padding: 0 25px;
  /* background-color: rgba(196, 196, 196, 0.8); */
  position: absolute;
  right: 0;
  top: 60px;
  border-radius: 10px 0 0 10px;
  width: 20%;
}

.add-friend-modal {
  display: flex;
  flex-direction: column;
}

.js-feedcontrol-overlay-video-button {
  margin-right: 100px;
}

.share-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #c4c4c4;
  align-items: center;
  height: 55px;
  margin-top: 10px;
}

.add-friend-search,
.social-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.social-container {
  justify-content: space-between;
  align-items: center;
}

.add-friend-input {
  padding: 5px 10px;
  outline: none;
  border: 2px solid #c4c4c4;
  border-radius: 8px 0 0 8px;
  width: 250px;
}

.add-friend-input::placeholder {
  color: #c4c4c4;
}
.friend-search-button {
  background-color: #9ac8b9;
  text-align: center;
  padding: 5px 10px;
  width: 80px;
  color: white;
  border-radius: 0 8px 8px 0;
  border-left: none;
  border-right: 2px solid #c4c4c4;
  border-top: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
}

.friend-search-button:focus {
  outline: none;
}

.live-video-control:hover {
  cursor: pointer;
}

.live-video-control-join-class:hover {
  cursor: pointer;
}

.friend-search-button:hover {
  cursor: pointer;
}

.friend-search-results-live {
  position: relative;
  height: 100px;
  width: 100%;
  border-radius: 8px;
  border: 2px solid #d6d6d6;
  border-top-width: 0.5px;
  padding: 15px 25px;
}

.fullscreen-dropdown-toggle {
  padding: 18px;
  background-color: rgba(196, 196, 196, 0.5);
  position: absolute;
  right: 0;
  display: flex;
  z-index: 99;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px 0 0 10px;
  top: 0px;
}

.two-way-profile-photo {
  border-radius: 100px;
  border: 1px solid #f3f6f5;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.two-way-profile-photo-wrapper {
  width: 52px;
  height: 52px;
}

.friend-profile-photo {
  border-radius: 100px;
  object-fit: contain;
  overflow: hidden;
  width: 35px;
  height: 35px;
}


@media (max-width: 767px) {

  .listview-two-way {
    padding: 20px;
    padding-bottom: 0px;
  }

}

.listview-two-way {
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.listview-two-way-wrapper {
  overflow-y: scroll;
  height: 100%;
}

.search-results-content {
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.add-friend-button {
  width: 35px;
  height: 35px;
  border-radius: 70px;
  background-color: rgb(154, 200, 185);
  color: white;
  font-size: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}



.your-video-stream {
  display: flex;
  width: 75px;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: space-between;
  
}

.your-video-stream:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 140px;
  background-color: var(--tooltip-color);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  transition: opacity .4s ease-in-out;
  position: absolute;
  z-index: 100;
  right: 0;
  top: 150%;
}

.your-video-stream:hover:before {
  opacity: 1;
  visibility: visible;
}

.video-streaming-toggle {
  height: 25px;
  display: flex;
  flex-direction: row;
  border-radius: 7px;
  background-color: white;
  box-shadow: -3px -1px 15px 3px rgba(0, 0, 0, 0.25);
}

.sort-container {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: #949494;
  border-bottom: 1px solid white;
}

.selected {
  border-bottom: 3px solid #5c5d59 !important;
}
.streaming-features-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.video-react {
  padding-top: 45% !important;
}

.dc-big-play-button {
  margin-top: 190px;
  z-index: 2;
}

.dc-spinner {
  margin-top: 190px;
}

.upcoming-livestream-classes-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #f2f6f5;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}



.upcoming-livestream-classes-list-item-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.upcoming-livestream-classes-list-item-left > * {
  margin-right: 15px;
}

.browse-on-demand {
  height: 51px;
  width: 250px;
  margin-right: 30px;
  margin-left: 5px;
  border-radius: 6px;
  padding: 16px, 32px, 16px, 32px;  
  border: none;
  
}

.browse-on-demand a {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: white !important;
}



#dacast-player {
  max-width: 1080px;
  margin: 0 auto;
}

.upcoming-livestream-studio-logo {
  border-radius: 100px;
  padding: 5px;
  border: 1px solid black;
  overflow: hidden;
  min-width: 52px;
  min-height: 52px;
}

.friend-indicator {
  border-radius: 15px;
  padding: 5px 10px;
  text-align: center;
  font-size: 10px;
  color: white;
  background-color: #818181;
}

.me-indicator {
  border-radius: 15px;
  border: 1px solid #818181;
  padding: 2px 5px;
  margin-left:5px;
  text-align: center;
  font-size: 10px;
  color: black;
  background-color: white;
}

.friend-indicator-add {
  border-radius: 15px;
  padding: 5px 15px;
  text-align: center;
  font-size: 10px;
  color: black;
  background-color: #f2c785;
}

.friend-indicator-add:hover {
  cursor: pointer;
}

.live-video-label-overlay {
  position: "absolute";
  padding: 5px 20px;
  border-radius: 8px;
  color: white;
  font-weight: bolder;
  font-size: 20px;
  top: 20px;
  left: 20px;
  background-color: "#9ac8b9";
}

.live-stream-schedule-date-wrapper {
  border-radius: 50%;
  width: 4.5em;
  height: 4.5em;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.live-banner-close:hover {
  cursor: pointer;
}

.live-stream-schedule-date-wrapper:hover {
  cursor: pointer;
}

.back-arrow-live-button {
  border: none;
  background-color: white !important;
  color: black !important;
  font-size: 32px;
}

.back-arrow-live-button:focus {
  outline: none;
}

.day-information-livestream {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
}

.dc-common-wrapper.dc-top-bar-wrapper {
  z-index: 1;
}

.jetsweat-live-channel-container {
  margin: 50px auto 50px auto;
  max-width: 1000px;
  padding: 0 3vh 0 3vh;
}

.jetsweat-live-video-description-container {
  border-left: 1px rgb(211, 207, 207) solid;
  height: 100%;
  padding: 10px 10px 10px 15px;
}

.jetsweat-live-save-the-children-container {
  background: #9AC8B9;
  min-height: 780px;
  height: 100%;
  justify-content: space-around;
}

.jetsweat-live-save-the-children-info {
  width: 390px;
  max-width: 100%;
  /* justify-content: space-between; */
  min-height: 650px;
  max-height: 100%;
}

.jetsweat-live-video-attributes-container {
  opacity: 0.6;
  font-size: 18px;
  height: fit-content;
  
}

.jetsweat-live-ondemand-invite-container {
  height: 560px;
  font-weight: 700;
  font-size: 36px;
  white-space: pre-line;
}

.jetsweat-live-advertisement-container {
  height: 100%
}

.jetsweat-live-event-partners-container {
  min-height: 720px;
  height: 100%;
  background: #9AC8B9;
  justify-content: space-evenly;
}

.jetsweat-live-event-partners-title-container {
  width: 100%
}

.jetsweat-live-event-partners-title-container h2 {
  width: 80%; 
  text-align: center; 
  border-bottom: 1px solid ; 
  border-color: rgb(255, 255, 255, 0.5);
  line-height: 0.1em;
  margin: 10px 0 20px; 
}

.js-live-sponsored h2 {
  border-color: rgb(0,0,0, 0.1) !important
}

.js-live-sponsored h2 a:hover{
  color: transparent;
}

.js-live-sponsored h2 span:hover {
  color:rgb(237, 74, 74, 0.4) !important
}

.js-live-sponsored h2 span {
  background: white !important;
  color: #9AC8B9 !important;
}

.jetsweat-live-event-partners-title-container h2 span {
  background:#9AC8B9; 
  padding:0 10px; 
  font-size: 18px;
  color: rgb(255, 255, 255, 0.5);
  font-family: Helvetica;
  font-weight: bold;
}

.event-partners-tile-container {
  width: calc(81%/3);
  height: 200px;
  justify-content: space-between;

}

.jetsweat-live-event-partners-list-container {
  width: 80%;
}

.js-live-event-partners-list-swiper {
  justify-content: space-between !important;
}

.js-live-try-button {
  font-size: 24px !important;

  width: 207px !important;

}

.js-live-donate-now {
  background-color: #ED1C25
}

/* Start: jetsweatlive page banner */
.virtual-banner-container{
  text-align: center;
  margin-bottom: 1rem;
}
.virtual-live-banner{
  width: 85% !important;
  margin: 0 auto;
}
/* End: jetsweatlive page banner */

.livestream-add-calendar:hover {
  cursor: pointer;
}

.add-to-calendar-span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.schedule-live-button {
  color: white;
  text-align: center;
  padding: 15px 60px;
  border-radius: 4px;
  font-size: 18px;
  background: #9ac8b9;
  /* margin-top: 50%; */
}

.schedule-live-button:hover {
  cursor: pointer;
}

.jetsweat-livestream-email-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mailchimp-subscribe button:focus{
  outline: 0;
}

.email-input-livestream-field {
  padding: 10px;
  color: black;
  border: none;
  border-radius: 4px;
  margin-bottom: 15px;
  background: rgba(113, 119, 131, 0.07);
}
.email-input-livestream-field::placeholder {
  color: #939393;
}
.email-input-livestream-field:focus {
  outline: 0;
}

.js-exit-button-modal-trigger {
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 4px;
  background: #9AC8B9;
  color: white
}
@media (max-device-width: 992px) {
  .jetsweat-live-video-description-container {
    border-top: 1px rgb(211, 207, 207) solid;
    border-left: none;
    margin-top: 20px;
    padding: 20px 10px 20px 10px;
  }
}

@media only screen and (max-device-width: 767px) and (orientation: landscape) {
  .video-panel-fullscreen {
    padding: 0 25px;
    background-color: rgba(196, 196, 196, 0.8);
    position: absolute;
    right: 0;
    top: 60px;
    border-radius: 10px 0 0 10px;
    width: 32%;
  }
  .video-panel-nfs {
    padding: 0 10px;
    width: 200%;
    /* padding-right: 2vw; */
    z-index: 50;
    /* padding-bottom: 10px; */
  }
  .your-video-stream {
    width: 100%;
    

  }
  .addfriend-text {
    font-size: 3vw;
    font-weight: 700;

  }
  .your-video-stream {
    background-color: #c4c4c4;
    align-items: flex-start;
  }
  .buffer {
    position: initial;
  }
}

@media only screen and (orientation: portrait) and (max-width: 800px) {
  .video-panel {
    padding: 0 10px;
    width: 90%;
    /* padding-right: 2vw; */
    z-index: 50;
    /* padding-bottom: 10px; */
  }
}

@media only screen and (orientation: portrait) and (max-width: 800px) {
  .your-video-stream {
    display: block;
    position: fixed;
    bottom: 0;
    background-color: #c4c4c4;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 5px;
    color: white;
  }

  .virtual-live-banner {
    width: 100% !important;
  }
  
  .jetsweat-live-save-the-children-info {
    padding: 8%;
  }

  .js-live-event-partners-list-swiper {
    height: 300px;
    transition-property: transform;
    transition-duration: .25s;
    transition-timing-function: ease-out;
    justify-content: center !important;
  }

  .jetsweat-live-event-partners-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .event-partners-tile-container {
    position: absolute;
    width: 80vw;
    justify-content: space-around !important;
  }

  .open {
    width: 100%;
  }
  .open-full {
    width: 60%;
  }
  .closed {
    width: 21vw;
    height: 12vh;
    border-radius: 5px 5px 0px 0px;
    align-items: center;
  }
  .closed svg {
    margin: 0.9vw;
  }
  .open svg {
    margin: 1.5vh 1vw;
  }
  .video-panel-fullscreen {
    padding: 0 10px;
    width: 50%;
    /* padding-right: 2vw; */
    z-index: 50;
    /* padding-bottom: 10px; */
  }
  .video-panel {
    padding: 0 10px;
    /* width: 90%; */
    /* padding-right: 2vw; */
    z-index: 50;
    /* padding-bottom: 10px; */
  }
  .addfriend-text {
    font-size: 3vw;
    font-weight: 700;
  }
  .streaming-features-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2px;
  }

}

.liveschedule-instructor-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 15%;
}

@media only screen and (max-width: 550px) {
  .live-stream-schedule-date-wrapper {
    width: 3em;
    height: 3em;
  }
  .container-content {
    display: grid;
    padding: 0px;
  }
  .class-type-container {
    width: 100%;
    margin-bottom: -30px;
    display: flex;
    align-items: center;
    flex-direction: column !important;
  }
  .classTypeImg {
    width: 80% !important;
    height: auto !important;
  }
  .liveschedule-instructor-row {
    width: 30%;
    flex-direction: column;
    align-items: baseline;
  }
  .liveschedule-instructor-row> div:first-child{
    padding-bottom: 5px;
  } 
  .class-type-name {
    width: 70vw;
    transform: translate(0%, -85px) !important;
  }
  .all-classes-container__class-type {
    display: block !important;
  }
}

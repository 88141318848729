
.image-container-education {
  width: 100%;
  overflow: hidden;
  display: grid;
  background-image: url(/static/media/workplace-wellness-firstPic.9bf1634f.png);
  height: 631px;
  background-size: cover;
  place-items: center
}

.first-img-education {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  object-fit: cover;
}

.overlay-container-education {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay-main-education {
  text-align: center; /* Center text horizontally */
  color: white;
  z-index: 1;
  margin-top: 60px;
  width: 90%;
}

.main-title-education {
  align-self: stretch;
  font-family: "Raleway";
  font-size: 51.78px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 5px;
}

.first-paragraph-education {
  width: 100%;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  padding: 0px 20px 40px 20px;
}

.first-button-education {
    background-color: transparent;
    border: 2px solid white; /* Set the border color to white */
    border-radius: 8px; /* Adjust the radius for rounded corners */
    color: #fff; /* Text color */
    padding: 10px 20px; /* Adjust padding as needed */
}

.two-columns-education {
    padding: 100px 100px;
    background-color: #F5F8F7;
    align-items: center;
    justify-content: space-between; /* Space between the columns */
    padding: 60px 100px;
  }

.campus-recreation-programming {
  display: flex;
  flex-direction: column; /* Add this to stack content vertically */
  justify-content: center;
  /*height: 210px; */
  margin-top: 30px;
   /* Adjust padding as needed */

}

.campus-recreation-programming-header {
  color: #000;
  font-family: "Raleway";
  font-size: 32px;
  font-weight: 700;
}

.campus-recreation-programming-body {
  color: #000;
  font-family: "Open Sans";
  font-size: 21px;
  font-weight: 400;
  line-height: 175%;
  width: 100%;
  margin-left: 0 !important;
  margin-bottom: 50px
}

.student-column {
  display: flex;
  height: 300px;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
  background-color: pink;
  margin-top: -25px;
}

.student-image {
  width: 175%;
  height: 120%;
  object-fit: cover;
}

.student-text {
  border: 1px solid #e8e8e8;
  background-color: #e8e8e8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  height: 200%;
}

.student-header {
  color: #000;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.student-body {
  color: #000;
  font-family: "Open Sans";
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  margin-left: 0 !important;
}

/* Education Section */
.education-needs {
  padding: 60px 100px;

  >div >h2 {
    text-align: left;
  }
}

.education-header {
  color: #000;
  text-align: left;
  margin-bottom: 40px;
  font-family: "Raleway";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.education-body {
  width: 700px;
  margin-bottom: 40px;
  color: #000;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
}

.three-columns-education {
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  gap: 60px;
}

.healthy-column {
  padding: 40px 40px 80px;
  width: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: left;
  border: 2px #9AC8B9 solid;
  border-radius: 14px;
  >p {
    margin: 0;
  }
}

.healthy-img-size {
  width: 141.97px;
  height: 150px;
  object-fit: contain;
}

.education-icon-and-header {
  display: flex;
  align-items: center;
  padding: 0;
  width: 100%;

  >img {
    width: 62px;
  }
}

.education-column-header {
  padding: 20px 0px;

  >h2 {
  color: #000;
  margin: 0;
  font-family: "Raleway";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }
}

.healthy-column-body {
  color: #000;
  font-family: "Open Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  margin: 0;
}

/* Education Section Ends */

.overlapping-percentage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.education-empower-text {
  flex: 1;
  position: relative;
  display: flex;
}

.education-empower-background {
  background-color: #F5F8F7;
  padding: 60px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.education-empower-paragraph {
  position: relative;
  padding-top: 30px;
  color: #000;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.education-empower-main-p {
  color: #000;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.education-empower-header {
  color: #000;
  padding: 20px;
  font-family: "Raleway";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.education-column-campus {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.percentage-text {
  position: absolute;
  font-size: 36px;
  font-weight: 700;
}

.percentage-first-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.percent-second-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;

}

.percent-seventyfour-img {
  position: absolute;
  top: 5;
  left: 5;
  z-index: 2;
  width: 140%;

}

.percent-twentyfive-img {
  position: absolute;
  top: 5;
  left: 5;
  z-index: 2;
  width: 110%;

}
/* Empower Health Section Ends */

.education-ignite-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  gap: 40px;
}

.education-ignite-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 40px 0 100px;
  
}

.education-ignite-header {
  color: #000;
  font-family: "Raleway";
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 40px;
}

.education-ignite-paragraph {
  color: #000;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 40px;
}

.second-button {
  background-color: black;
  border: 2px solid black ;
  border-radius: 8px;
  color: white;
  padding: 10px 20px;
  margin-bottom :60px
}

.education-ignite-image {
  width: 50%;
}


/* Modal Section */
.workplace-modal {
  display:flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: black;
  justify-content: center;

}

.modal-main {
  background-color: white;
  padding: 20px 0 20px;
  border-radius: 5px;
  width: 600px;
  max-height: 70vh;
  overflow: scroll;

}


.modal-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none;
  >h3 {
    text-align: center;
    margin: 0;
    width: 100%;
    font-weight: 700;
    font-family: "Raleway";
  }
  >p {
    width: 100%;
    margin: 0;
    text-align: center;
    font-family: "Raleway";
    font-size: 1.2em;
  }
}

.hubspot-form {
  padding: 20px 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.hs-phone {
  height: 100%;
}

.hs_error_rollup {
  padding: 10px;
  display: none;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-align: center;
}
.hs-error-msgs {
  padding: 0;
  margin: 0;
}
.hubspot-form .input {
  margin-right: 0 !important;
  padding-right: 0 !important;
}
.hubspot-form .hs-input {
  width: 100% !important;
}
.hubspot-form .form-columns-2 {
  display: flex;
  gap: 20px;
}
.hubspot-form .form-columns-1 {
  width: 100%;
}
.hs-fieldtype-text {
  height: auto;
}

.hubspot-form .hs-form-field {
  border: none;
  margin-bottom: 10px;

  > label {
  font-weight: bold;
  }
}

.hubspot-form .field .hs-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid lightgray;
}
.hubspot-form .form-columns-2 input {
  width: 90%;
}

.hs-message {
  height: 100%;
  margin: 0px;
  padding: 0;
}

.hs-message .input .hs-fieldtype-textarea{
  height :150px;
}
.hubspot-form .hs-button {
  background-color: black !important;
  width: 120px;
  height: 40px;
  color:white;
  border-radius: 5px;
  text-decoration: none;

}


.hs-error-msgs {
  width: 90%;
  >li {
    list-style-type: none;
  }
}

.close-modal-button {
  position: absolute;
  right: 0;
  margin-right: 40px;
  border: none;
  background-color: transparent;
  z-index: 4;
}

body.workplace-modal-open {
  overflow: hidden;
}

.hs-submit {
  padding-top: 20px;
}

/* tablet version */
@media (max-width: 1100px) {

  .main-title {
    font-size: 3em;
  }

  .first-paragraph {
    font-size: 1.2em;
    font-weight: 500;
    line-height: 30px;
    padding-bottom: 40px;
  }

  .two-columns {
    gap: 20px;
    padding: 100px 40px;
  }

  .campus-two-columns {
    gap: 20px;
    padding: 100px 40px;
  }

  .hr-text {
    padding: 20px 10px;
  }

  .healthy-needs {
    padding: 150px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .healthy-column {
    height: 600px;
  }

  .empower-background {
    padding: 60px 40px;
    height: 100%;
  }

  .ignite-container {
    padding: 0px 0 0px 40px;
    height: 100%;
  }
  .ignite-text {
    padding: 20px 0;
    height: 100%;
  }
  .ignite-header {
    margin-bottom: 60px;
  }
  .ignite-paragraph {
    margin-bottom: 60px;
  }

  /* Modal section */
}

/* mobile version */
@media (max-width: 625px) {
  body {
    padding: 0;
    margin: 0;
  }
  .overlay-main-education {
    width: 80%;
  }

  .image-container-education {
    background-position: center;
  }

  .main-title-education {
    font-size: 2em;
    margin-bottom: 40px;
  }

  .first-paragraph-education {
    line-height: 24px;
    font-size: 1em;
  }

  .two-columns-education {
    flex-direction: column;
    padding: 20px 20px;
  }

  .campus-recreation-programming {
    
    > h2 {
      font-size: 22px;
    }
    > p {
      font-size: 16px;
    }
  }

  .student-column {
    flex-direction: column;
    height: 100%;
  }

  .student-image {
    height: 200px;
    width: 100%;
  }

  /* Education Section */
  .education-needs{ 
    display: flex;
    flex-direction: column;
    padding: 40px 20px;

  }

  .education-header {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .education-body {
    font-size: 16px;
    line-height: normal;
  }

  .three-columns-education {
    flex-direction: column;
    gap: 20px;
    padding: 0 25px;
  }

  .healthy-column {
    width: 100%;
    padding: 20px 40px 60px;
    height: 100%;
  }
  /* Education Section Ends */

  /* Empower Section */
  .education-empower-background {
    padding: 40px 20px;
  }
  .education-empower-header {
    padding: 0;
    font-size: 22px;
  }
  .education-empower-main-p {
    margin: 0;
    font-size: 16px;
  }
  /* Empower Section Ends */

  /* Ignite Section */
  .education-ignite-container{ 
    padding: 0;
    flex-direction: column;
  }

  .education-ignite-header {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .education-ignite-paragraph {
    font-size: 16px;
  }
  .education-ignite-image {
    width: 100%;
    order: 1;
    object-position: 0;
  }

  .education-ignite-text {
    order: 2;
    padding: 0 20px;
  }
  /* Ignite Section Ends */
  
  /* Modal Section */
  .workplace-modal {
    width: 80%;
    margin: auto;
    margin-top: 100px;
    max-height: 80vh;
  }
  .hubspot-form {
    padding: 0 20px;
    margin: 0;
    width: 100%;

  }
  .modal-header {
    padding: 20px 10px;
    margin-bottom: 10px;
    >h3 {
      font-size: 1.5em;
    }
    >p {
      font-size: 1em;
    }
  }
  .hubspot-form .form-columns-2 {
    display: flex;
    flex-direction: column;
    margin: 0;
    gap: 0;
  }

  .hubspot-form .form-columns-1 .hs-message{
     margin: 0px !important;
     padding: 0px;

  }
  .hs-form-private {
    width: 90%;
  }

  .hs-error-msgs {
    width: 90%;
    >li {
      font-size: .8em;
      list-style-type: none;
    }
  }
  .hs-message .input .hs-fieldtype-textarea{ 
    height :100px;
  }

  .close-modal-button {
    margin-right: 20px;
  }
}

@media (max-height: 800px) {
  .workplace-modal{
    margin-top: 40px;
  }
}
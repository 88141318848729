.text-bold {
  font-weight: 600;
}

.text-white {
  color: white;
}

.oneBlock {
  text-align: center;
  margin:10px;
}

.promo-button {
  padding: 10px 30px;
  border-radius: 10px;
  margin: 10px auto;
  display: inline-block;
  background-color: rgb(163, 210, 195);
}

.fade-hover:hover {
  text-decoration: none;
  opacity: 0.7;
}

.promo-icon {
  width: 70px;
  margin: 10px;
}

.flex-container {
  display: flex;
}

.bg-theme {
  background-color: rgb(163, 210, 195);
}

.header-container {
  margin: 10px auto;
}

.header-container > h2 {
  margin: 5px auto;
  padding: 20px;
  text-align: center;
}

.header-img {
  max-width:50vw;
  margin: 0 auto;
}
.header-img > img {
  width: 100%;
}

.studio-logo {
  padding: 25px;
  display:flex;
  align-items:center;
  justify-content:center;
  flex: 1 1 20%;
  max-width: 120px;
  min-width: 100px;
}
.studio-logo-image {
  width: 100%;
}
.all-classes-container__studio-logos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
  margin-top: 40px;
  background-color: white;
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.flexContainer > div {
  flex-grow: 1;
}
